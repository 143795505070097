import { Anchor, Flex } from '@mantine/core';
import './Home.scss';

const Home = (): JSX.Element => {
  return (
    <Flex className="home" h={'100dvh'} w={'100%'} justify={'center'} align={'center'}>
      <img src="/logo.png" width={200} />
      <Flex className="info" align={'center'} justify={'center'}>
        <Anchor
          fz={12}
          mr={12}
          td={'underline'}
          href="https://pinyourthoughts.notion.site/Pin-Your-Thoughts-PYT-Privacy-Policy-3f697c1294bc4cd787073a0bb67cfa3c?pvs=4"
          target="_blank"
        >
          Privacy Policy
        </Anchor>
        <Anchor
          fz={12}
          mx={12}
          td={'underline'}
          href="https://pinyourthoughts.notion.site/Pin-Your-Thoughts-PYT-Terms-of-Use-58856d3ad82343e88985d17294c91014?pvs=4"
          target="_blank"
        >
          Terms of conditions
        </Anchor>
        <Anchor
          fz={12}
          ml={12}
          td={'underline'}
          href="mailto:pinyourthoughtsapp@gmail.com"
          target="_blank"
        >
          Support
        </Anchor>
      </Flex>
    </Flex>
  );
};

export default Home;
