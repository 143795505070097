import { Button, Container, Flex, Grid, Text, TextInput, Title } from '@mantine/core';
import './Timings.scss';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import useAdminGetTimings from '../../hooks/useAdminGetTimings';
import useAdminUpdateTimings from '../../hooks/useAdminUpdateTimings';
import { useEffect } from 'react';

const editTimingsScheme = z.object({
  id: z.string().nonempty(),
  maxMin: z.string().nonempty(),
  minMin: z.string().nonempty(),
  minComments: z.string().nonempty(),
  maxComments: z.string().nonempty(),
  minLikes: z.string().nonempty(),
  maxLikes: z.string().nonempty(),
  minShares: z.string().nonempty(),
  maxShares: z.string().nonempty(),
});

const Timings = (): JSX.Element => {
  const navigate = useNavigate();
  const { data: timings, isLoading: isTimingsLoading } = useAdminGetTimings();
  const { mutate: editTimings, isLoading: isUpdatingTimings } = useAdminUpdateTimings();

  const editTimingsForm = useForm({
    initialValues: {
      id: '',
      maxMin: '',
      minMin: '',
      minComments: '',
      maxComments: '',
      minLikes: '',
      maxLikes: '',
      minShares: '',
      maxShares: '',
    },
    validate: zodResolver(editTimingsScheme),
  });

  const handleEditTimings = (values: {
    id: string;
    maxMin: string;
    minMin: string;
    minComments: string;
    maxComments: string;
    minLikes: string;
    maxLikes: string;
    minShares: string;
    maxShares: string;
  }): void => {
    editTimings({
      id: parseInt(values.id),
      maxMin: parseInt(values.maxMin),
      minMin: parseInt(values.minMin),
      minComments: parseInt(values.minComments),
      maxComments: parseInt(values.maxComments),
      minLikes: parseInt(values.minLikes),
      maxLikes: parseInt(values.maxLikes),
      minShares: parseInt(values.minShares),
      maxShares: parseInt(values.maxShares),
    });
  };

  useEffect(() => {
    if (timings) {
      editTimingsForm.setValues({
        id: timings.id.toString(),
        maxMin: timings.maxMin.toString(),
        minMin: timings.minMin.toString(),
        minComments: timings.minComments.toString(),
        maxComments: timings.maxComments.toString(),
        minLikes: timings.minLikes.toString(),
        maxLikes: timings.maxLikes.toString(),
        minShares: timings.minShares.toString(),
        maxShares: timings.maxShares.toString(),
      });
    }
  }, [timings]);

  return (
    <Container>
      <Grid justify="center">
        <Grid.Col span={12} md={10} lg={8} mt={'lg'}>
          {isTimingsLoading && 'Loading...'}
          {!isTimingsLoading && timings && (
            <>
              <Text td="underline" className="cursor-pointer" onClick={() => navigate(-1)}>
                Back to Dashboard
              </Text>

              <form onSubmit={editTimingsForm.onSubmit(handleEditTimings)}>
                <Title mt={'lg'}>Timings</Title>

                <Flex>
                  <TextInput
                    mt={'md'}
                    mr={'lg'}
                    placeholder="Min time range in minutes"
                    label="Min time range in minutes"
                    {...editTimingsForm.getInputProps('minMin')}
                  />
                  <TextInput
                    mt={'md'}
                    placeholder="Max time range in minutes"
                    label="Max time range in minutes"
                    {...editTimingsForm.getInputProps('maxMin')}
                  />
                </Flex>

                <Flex>
                  <TextInput
                    mt={'md'}
                    mr={'lg'}
                    placeholder="Min comments"
                    label="Min comments"
                    {...editTimingsForm.getInputProps('minComments')}
                  />
                  <TextInput
                    mt={'md'}
                    placeholder="Max comments"
                    label="Max comments"
                    {...editTimingsForm.getInputProps('maxComments')}
                  />
                </Flex>

                <Flex>
                  <TextInput
                    mt={'md'}
                    mr={'lg'}
                    placeholder="Min likes"
                    label="Min likes"
                    {...editTimingsForm.getInputProps('minLikes')}
                  />
                  <TextInput
                    mt={'md'}
                    placeholder="Max likes"
                    label="Max likes"
                    {...editTimingsForm.getInputProps('maxLikes')}
                  />
                </Flex>

                <Flex>
                  <TextInput
                    mt={'md'}
                    mr={'lg'}
                    placeholder="Min shares"
                    label="Min shares"
                    {...editTimingsForm.getInputProps('minShares')}
                  />
                  <TextInput
                    mt={'md'}
                    placeholder="Max shares"
                    label="Max shares"
                    {...editTimingsForm.getInputProps('maxShares')}
                  />
                </Flex>

                <Button
                  mt={'lg'}
                  w={'100%'}
                  type="submit"
                  loading={isUpdatingTimings}
                  loaderPosition="right"
                  loaderProps={{
                    ml: 'sm',
                  }}
                >
                  Save
                </Button>
              </form>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Timings;
