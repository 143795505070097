import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiAdminGeneratePinKey } from '../api/apiReferencesContants';
import { ApiAdminGeneratePin } from '../api/apiReferences';

export const getAdminGeneratePinKey = (): QueryKey => [ApiAdminGeneratePinKey];

const useAdminGeneratePin = (): {
  mutate: UseMutateFunction<
    string,
    unknown,
    {
      persona: string;
      appContext: string;
      count: number;
      task: string;
      temp: number;
      tokenLimit: number;
      rules: string;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getAdminGeneratePinKey(),
    async (data: {
      persona: string;
      appContext: string;
      count: number;
      task: string;
      temp: number;
      tokenLimit: number;
      rules: string;
    }) => {
      const res = await ApiAdminGeneratePin(data);
      return res;
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useAdminGeneratePin;
