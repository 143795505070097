import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  ScrollArea,
  Text,
  Textarea,
} from '@mantine/core';
import './Comments.scss';
import useAdminGetPinComments from '../../hooks/useAdminGetPinComments';
import { useEffect, useRef, useState } from 'react';
import { PinComment } from '../../types';
import dayjs from 'common/utils/dayjs';
import useAdminDeletePinComment from '../../hooks/useAdminDeletePinComment';
import { useNavigate } from 'react-router-dom';
import useAdminGetPromptDetails from '../../hooks/useAdminGetPromptDetails';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import useAdminUpdatePromptDetails from '../../hooks/useAdminUpdatePromptDetails';
import { debounce } from 'lodash';

const promptDetailsSchema = z.object({
  id: z.number(),
  context: z.string().nonempty({ message: 'Context is required' }),
  task: z.string().nonempty({ message: 'Task is required' }),
  rules: z.string().nonempty({ message: 'Rules is required' }),
  type: z.string().nonempty({ message: 'Type is required' }),
});

const Comments = (): JSX.Element => {
  const { data, isLoading, hasNextPage, fetchNextPage } = useAdminGetPinComments();
  const [comments, setComments] = useState<PinComment[]>([]);
  const viewport = useRef<HTMLDivElement>(null);
  const { mutate, isLoading: isDeleteLoading } = useAdminDeletePinComment();
  const navigate = useNavigate();
  const { data: promptDetails, isLoading: isPromptDetailsLoading } =
    useAdminGetPromptDetails('comment');

  const { mutate: updatePromptDetails, isLoading: isUpdatingPromptDetails } =
    useAdminUpdatePromptDetails();

  const promptDetailsForm = useForm({
    initialValues: {
      id: 0,
      context: '',
      task: '',
      rules: '',
      type: '',
    },
    validate: zodResolver(promptDetailsSchema),
  });

  useEffect(() => {
    if (promptDetails) {
      promptDetailsForm.setValues({
        id: promptDetails.id,
        context: promptDetails.context,
        task: promptDetails.task,
        rules: promptDetails.rules,
        type: promptDetails.type,
      });
    }
  }, [promptDetails]);

  useEffect(() => {
    if (data) {
      const flattenData = data.pages.flatMap((page) => page.comments);
      setComments(flattenData);
    }
  }, [data]);

  const loadNext = (): void => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const handleDebouncedLoadNext = debounce(loadNext, 250);

  const handleUpdatePromptDetails = (values: z.infer<typeof promptDetailsSchema>): void => {
    updatePromptDetails(values);
  };

  return (
    <ScrollArea
      viewportRef={viewport}
      onScrollPositionChange={() => {
        if (
          viewport.current &&
          viewport.current.scrollHeight - viewport.current.scrollTop <
            viewport.current.clientHeight + 400
        ) {
          handleDebouncedLoadNext();
        }
      }}
      pt={'lg'}
      h={'100dvh'}
    >
      <Container>
        <Grid justify="center">
          <Grid.Col span={12} md={10} lg={8}>
            <Text td="underline" className="cursor-pointer" onClick={() => navigate(-1)} mb={'md'}>
              Back to Dashboard
            </Text>
            {!isPromptDetailsLoading && data && (
              <Box>
                <Text>Comment Prompt Details</Text>
                <form onSubmit={promptDetailsForm.onSubmit(handleUpdatePromptDetails)}>
                  <Textarea
                    mt={'lg'}
                    placeholder="Context"
                    label="Context"
                    minRows={5}
                    {...promptDetailsForm.getInputProps('context')}
                  />
                  <Textarea
                    mt={'lg'}
                    placeholder="Task"
                    label="Task"
                    minRows={5}
                    {...promptDetailsForm.getInputProps('task')}
                  />
                  <Textarea
                    mt={'lg'}
                    placeholder="Rules"
                    label="Rules"
                    minRows={5}
                    {...promptDetailsForm.getInputProps('rules')}
                  />
                  <Button
                    mt={'lg'}
                    w={'100%'}
                    type="submit"
                    loading={isUpdatingPromptDetails}
                    loaderPosition="right"
                    loaderProps={{
                      ml: 'sm',
                    }}
                  >
                    Update Details
                  </Button>
                </form>
              </Box>
            )}

            <Divider my={24} />
            {comments.map((comment) => (
              <Box
                key={comment.id}
                mb={'lg'}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                }}
                p={'md'}
              >
                <Text>{comment.pin.message}</Text>
                <Flex>
                  <Text fz={12} color="gray.7" mt={'sm'} mr={'md'}>
                    {comment.pin.user.username}
                  </Text>
                  <Text fz={12} color="gray.7" mt={'sm'}>
                    {dayjs(comment.pin.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                  </Text>
                </Flex>
                <Divider my={'md'} />
                <Text fz={14} color="gray.7">
                  {comment.comment}
                </Text>
                <Flex>
                  <Text fz={12} color="gray.7" mt={'sm'} mr={'md'}>
                    {comment.user.username}
                  </Text>
                  <Text fz={12} color="gray.7" mt={'sm'}>
                    {dayjs(comment.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                  </Text>
                </Flex>
                <Button
                  mt={'md'}
                  color="red"
                  compact
                  radius={'sm'}
                  onClick={() => {
                    mutate(comment.id);
                  }}
                  loading={isDeleteLoading}
                  loaderPosition="right"
                  loaderProps={{
                    ml: 'sm',
                  }}
                >
                  Delete
                </Button>
              </Box>
            ))}
            {isLoading && 'Loading...'}
            {!isLoading && !hasNextPage && 'No more comments'}
          </Grid.Col>
        </Grid>
      </Container>
    </ScrollArea>
  );
};

export default Comments;
