import Api from 'common/utils/axios/http';
import { Admin } from 'services/admin/types';

export const ApiAdminLogin = (data: { email: string; password: string }): Promise<Admin> => {
  return Api.put('admin/auth/login', data).then((res) => res.data);
};

export const ApiAdminLogout = (): Promise<void> => {
  return Api.put('admin/auth/logout').then((res) => res.data);
};

export const ApiAdminCheckSession = (): Promise<Admin> => {
  return Api.get('admin/auth').then((res) => res.data);
};
