import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiAdminCreatePinKey } from '../api/apiReferencesContants';
import { ApiAdminCreatePin } from '../api/apiReferences';
import { notifications } from '@mantine/notifications';

export const getAdminCreatePinKey = (): QueryKey => [ApiAdminCreatePinKey];

const useAdminCreatePin = (): {
  mutate: UseMutateFunction<
    string,
    unknown,
    {
      message: string;
      lat: number;
      lng: number;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getAdminCreatePinKey(),
    async (data: { message: string; lat: number; lng: number }) => {
      const res = await ApiAdminCreatePin(data);
      return res;
    },
    {
      onSuccess: () => {
        notifications.show({
          message: `Pin created successfully!`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.green[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useAdminCreatePin;
