import { Button, Container, Grid, Select, TextInput, Textarea, Title, Text } from '@mantine/core';
import useAdminCreatePin from '../../hooks/useAdminCreatePin';
import useAdminGeneratePin from '../../hooks/useAdminGeneratePin';
import useAdminGetAllPersonas from '../../hooks/useAdminGetAllPersonas';
import './GeneratePin.scss';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';

const generatePinSchema = z.object({
  persona: z.string().nonempty({ message: 'Persona is required' }),
  appContext: z.string().nonempty({ message: 'App context is required' }),
  count: z.string().nonempty({ message: 'Count is required' }),
  task: z.string().nonempty({ message: 'Task is required' }),
  temp: z.string().nonempty({ message: 'Count is required' }),
  tokenLimit: z.string().nonempty({ message: 'Count is required' }),
  rules: z.string().nonempty({ message: 'Rules is required' }),
});

const createPinSchema = z.object({
  message: z.string().nonempty({ message: 'Message is required' }),
  lat: z.number(),
  lng: z.number(),
});

const GeneratePin = (): JSX.Element => {
  const { data: personas, isLoading: isPersonasLoading } = useAdminGetAllPersonas();
  const { mutate: generatePin, isLoading: isGeneratePinLoading } = useAdminGeneratePin();
  const { mutate: createPin, isLoading: isCreatePinLoading } = useAdminCreatePin();
  const navigate = useNavigate();

  const generatePinForm = useForm({
    initialValues: {
      persona: '',
      appContext: 'There is a new social app that lets you pin any thoughts on a map.',
      task: 'Based on your role, create one pin on the map and write a message about an emotion you felt or an anecdote that happened to you while you were at that location.',
      count: '1',
      temp: '1',
      tokenLimit: '90',
      rules: `1. mention the location of the pin. Example: location, city, country
2. Add latitude and longitude decimal degrees. Example: latitude: 33.01874 and longitude: -96.70009
3. Refrain from including the location of your home.     
4. Make sure that the length of the response is below three hundred characters.    
5. Use lowercase script    
6. NO HASHTAGS must be included in the text
7. Use between zero and three emojis in the text`,
    },
    validate: zodResolver(generatePinSchema),
  });

  const createPinForm = useForm({
    initialValues: {
      message: '',
      lat: 0,
      lng: 0,
    },
    validate: zodResolver(createPinSchema),
  });

  const handleGeneratePin = (values: {
    persona: string;
    appContext: string;
    task: string;
    count: string;
    temp: string;
    tokenLimit: string;
    rules: string;
  }): void => {
    generatePin(
      {
        persona: values.persona,
        appContext: values.appContext,
        task: values.task,
        count: parseInt(values.count),
        temp: parseInt(values.temp),
        tokenLimit: parseInt(values.tokenLimit),
        rules: values.rules,
      },
      {
        onSuccess: (data) => {
          const lat = data.split('latitude: ')[1].split(',')[0].trim();
          const lng = data.split('longitude: ')[1].split('\n')[0].trim();
          const message = data.split('message: ')[1].trim();

          createPinForm.setFieldValue('lat', parseFloat(lat));
          createPinForm.setFieldValue('lng', parseFloat(lng));
          createPinForm.setFieldValue('message', message);
        },
      },
    );
  };

  const handleCreatePin = (values: { message: string; lat: number; lng: number }): void => {
    createPin(values);
  };

  return (
    <Container>
      <Grid justify="center">
        <Grid.Col span={12} md={10} lg={8} mt={'lg'}>
          {isPersonasLoading && 'Loading...'}
          {!isPersonasLoading && personas && (
            <>
              <form onSubmit={generatePinForm.onSubmit(handleGeneratePin)}>
                <Text td="underline" className="cursor-pointer" onClick={() => navigate(-1)}>
                  Back to Dashboard
                </Text>
                <Title mt={'lg'}>Generate a pin</Title>
                <Select
                  label="Choose a persona"
                  placeholder="Pick one"
                  data={
                    personas.map((persona) => ({
                      value: persona.persona,
                      label: persona.persona,
                    })) || []
                  }
                  value={generatePinForm.values.persona}
                  onChange={(value) => generatePinForm.setFieldValue('persona', value as string)}
                />
                <Textarea
                  mt={'md'}
                  placeholder="Persona"
                  label="Persona"
                  minRows={5}
                  {...generatePinForm.getInputProps('persona')}
                />
                <Textarea
                  mt={'lg'}
                  placeholder="App context"
                  label="App context"
                  minRows={5}
                  {...generatePinForm.getInputProps('appContext')}
                />
                <Textarea
                  mt={'lg'}
                  placeholder="Task"
                  label="Task"
                  minRows={5}
                  {...generatePinForm.getInputProps('task')}
                />
                <Textarea
                  mt={'lg'}
                  placeholder="Rules"
                  label="Rules"
                  minRows={5}
                  {...generatePinForm.getInputProps('rules')}
                />
                <TextInput
                  mt={'lg'}
                  label="Temp"
                  placeholder="1"
                  {...generatePinForm.getInputProps('temp')}
                />
                <TextInput
                  mt={'lg'}
                  label="Token Limit"
                  placeholder="90"
                  {...generatePinForm.getInputProps('tokenLimit')}
                />
                <TextInput
                  mt={'lg'}
                  label="How many pins to generate?"
                  placeholder="1"
                  type="number"
                  {...generatePinForm.getInputProps('count')}
                />
                <Button
                  mt={'lg'}
                  w={'100%'}
                  type="submit"
                  loading={isGeneratePinLoading}
                  loaderPosition="right"
                  loaderProps={{
                    ml: 'sm',
                  }}
                >
                  Generate
                </Button>
              </form>
              <form onSubmit={createPinForm.onSubmit(handleCreatePin)}>
                <Title mt={'xl'}>Result</Title>
                <Textarea
                  mt={'lg'}
                  placeholder="Result"
                  label="Result"
                  minRows={5}
                  {...createPinForm.getInputProps('message')}
                />
                {/* show the count of the message */}
                <Text>{createPinForm.values.message.length}</Text>
                <TextInput
                  mt={'lg'}
                  label="Lat"
                  placeholder="0"
                  {...createPinForm.getInputProps('lat')}
                />
                <TextInput
                  mt={'lg'}
                  label="Lng"
                  placeholder="0"
                  {...createPinForm.getInputProps('lng')}
                />
                <Button
                  my={'lg'}
                  w={'100%'}
                  type="submit"
                  loading={isCreatePinLoading}
                  loaderPosition="right"
                  loaderProps={{
                    ml: 'sm',
                  }}
                >
                  Create Pin
                </Button>
              </form>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default GeneratePin;
