export const ApiAdminCreatePersonaKey = 'ApiAdminCreatePersonaKey';
export const ApiAdminGetAllPersonasKey = 'ApiAdminGetAllPersonasKey';
export const ApiAdminGetPersonaKey = 'ApiAdminGetPersonaKey';
export const ApiAdminUpdatePersonaKey = 'ApiAdminUpdatePersonaKey';
export const ApiAdminDeletePersonaKey = 'ApiAdminDeletePersonaKey';
export const ApiAdminGeneratePinKey = ' ApiAdminGeneratePinKey';
export const ApiAdminCreatePinKey = 'ApiAdminCreatePinKey';
export const ApiAdminGetAllPinCommentsKey = 'ApiAdminGetAllPinCommentsKey';
export const ApiAdminDeletePinCommentKey = 'ApiAdminDeletePinCommentKey';
export const ApiAdminGetPromptDetailsKey = 'ApiAdminGetPromptDetailsKey';
export const ApiAdminUpdatePromptDetailsKey = 'ApiAdminUpdatePromptDetailsKey';
export const ApiAdminGetTimingsKey = 'ApiAdminGetTimingsKey';
export const ApiAdminUpdateTimingsKey = 'ApiAdminUpdateTimingsKey';
export const ApiAdminUpdateFakeUserPhotosKey = 'ApiAdminUpdateFakeUserPhotosKey';
export const ApiAdminGetAllFakeUsersKey = 'ApiAdminGetAllFakeUsersKey';
