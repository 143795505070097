import { queryClient } from 'common/utils/queryClient';
import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiAdminUpdatePersonaKey } from '../api/apiReferencesContants';
import { ApiAdminUpdatePersona } from '../api/apiReferences';
import { Persona } from '../types';
import { notifications } from '@mantine/notifications';
import { getAdminGetAllPersonasKey } from './useAdminGetAllPersonas';

export const getAdminUpdatePersonaKey = (): QueryKey => [ApiAdminUpdatePersonaKey];

const useAdminUpdatePersona = (): {
  mutate: UseMutateFunction<
    Persona,
    unknown,
    {
      id: number;
      persona: string;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getAdminUpdatePersonaKey(),
    async (data: { id: number; persona: string }) => {
      const res = await ApiAdminUpdatePersona(data.id, data.persona);
      return res;
    },
    {
      onSuccess: async () => {
        notifications.show({
          message: `Persona updated successfully!`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.green[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });

        await queryClient.invalidateQueries(getAdminGetAllPersonasKey());
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useAdminUpdatePersona;
