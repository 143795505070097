import { InfiniteData, QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { ApiAdminGetAllPinCommentsKey } from '../api/apiReferencesContants';
import { ApiAdminGetAllPinComments } from '../api/apiReferences';
import { PinComment } from '../types';

export const getAdminGetPinCommentsKey = (): QueryKey => [ApiAdminGetAllPinCommentsKey];

const useAdminGetPinComments = (): {
  isLoading: boolean;
  data?: InfiniteData<{
    comments: PinComment[];
    nextOffset: number;
  }>;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
} => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    getAdminGetPinCommentsKey(),
    async ({ pageParam = 0 }: { pageParam?: number }) => {
      const res = await ApiAdminGetAllPinComments(10, pageParam);
      return {
        comments: res,
        nextOffset: pageParam + 10,
      };
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.comments.length < 10) return undefined;
        return lastPage.nextOffset;
      },
    },
  );

  return {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
  };
};

export default useAdminGetPinComments;
