import useAdminAuth from 'services/admin/auth/hooks/useAdminAuth';
import './Dashboard.scss';
import { Button, Container, Grid, Group, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const Dashboard = (): JSX.Element => {
  const { isLoading } = useAdminAuth();
  const navigate = useNavigate();

  return (
    <Container>
      <Grid justify="center">
        <Grid.Col span={12} md={10} lg={8} mt={'lg'}>
          {isLoading && 'Loading...'}
          {!isLoading && (
            <>
              <Title align="center">Dashboard</Title>
              <Group mt={'xl'}>
                <Button mt={'lg'} onClick={() => navigate('generate-pin')}>
                  Generate a pin
                </Button>
                <Button mt={'lg'} onClick={() => navigate('personas')}>
                  Add/edit personas
                </Button>
                <Button mt={'lg'} onClick={() => navigate('comments')}>
                  Review AI-generated comments
                </Button>
                <Button mt={'lg'} onClick={() => navigate('timings')}>
                  Update timings
                </Button>
                <Button mt={'lg'} onClick={() => navigate('fake-users')}>
                  Update fake user photos
                </Button>
              </Group>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Dashboard;
