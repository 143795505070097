import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiAdminDeletePinCommentKey } from '../api/apiReferencesContants';
import { ApiAdminDeletePinComment } from '../api/apiReferences';
import { notifications } from '@mantine/notifications';
import { queryClient } from 'common/utils/queryClient';
import { getAdminGetPinCommentsKey } from './useAdminGetPinComments';

export const getAdminDeletePinCommentKey = (): QueryKey => [ApiAdminDeletePinCommentKey];

const useAdminDeletePinComment = (): {
  mutate: UseMutateFunction<void, unknown, number, unknown>;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getAdminDeletePinCommentKey(),
    async (id: number) => {
      const res = await ApiAdminDeletePinComment(id);
      return res;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getAdminGetPinCommentsKey());

        notifications.show({
          message: `Comment delete successfully!`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.green[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useAdminDeletePinComment;
