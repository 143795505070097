import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiAdminGetPromptDetailsKey } from '../api/apiReferencesContants';
import { ApiAdminGetPromptDetails } from '../api/apiReferences';
import { PromptDetails } from '../types';

export const getAdminGetPromptDetailsKey = (type: string): QueryKey => [
  ApiAdminGetPromptDetailsKey,
  type,
];

const useAdminGetPromptDetails = (
  type: string,
): {
  isLoading: boolean;
  data?: PromptDetails;
} => {
  const { data, isLoading } = useQuery(getAdminGetPromptDetailsKey(type), async () => {
    const res = await ApiAdminGetPromptDetails(type);
    return res;
  });

  return {
    isLoading,
    data,
  };
};

export default useAdminGetPromptDetails;
