import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiAdminGetTimingsKey } from '../api/apiReferencesContants';
import { Timing } from '../types';
import { ApiAdminGetTimings } from '../api/apiReferences';

export const getAdminGetTimingsKey = (): QueryKey => [ApiAdminGetTimingsKey];

const useAdminGetTimings = (): {
  isLoading: boolean;
  data?: Timing;
} => {
  const { data, isLoading } = useQuery(getAdminGetTimingsKey(), async () => {
    const res = await ApiAdminGetTimings();
    return res;
  });

  return {
    isLoading,
    data,
  };
};

export default useAdminGetTimings;
