import { Button, Container, Grid, TextInput } from '@mantine/core';
import './Login.scss';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import useAdminLogin from '../../hooks/useAdminLogin';

const schema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
  password: z.string().nonempty({ message: 'Password is required' }),
});

const Login = (): JSX.Element => {
  const { mutate, isLoading } = useAdminLogin();
  const form = useForm({
    initialValues: { email: '', password: '' },
    validate: zodResolver(schema),
  });

  const handleSubmit = (values: { email: string; password: string }): void => {
    mutate(values);
  };

  return (
    <Container>
      <Grid justify="center">
        <Grid.Col span={12} md={8} lg={4} mt={'xl'}>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              label="Email"
              placeholder="your@email.com"
              type="email"
              {...form.getInputProps('email')}
            />

            <TextInput
              label="Password"
              placeholder="******"
              type="password"
              {...form.getInputProps('password')}
              mt={'md'}
            />

            <Button
              mt={'lg'}
              w={'100%'}
              type="submit"
              loading={isLoading}
              loaderPosition="right"
              loaderProps={{
                ml: 'sm',
              }}
            >
              Login
            </Button>
          </form>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Login;
