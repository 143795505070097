import { Button, Container, Grid, Select, Text, Textarea, Title } from '@mantine/core';
import './Personas.scss';
import { useNavigate } from 'react-router-dom';
import useAdminGetAllPersonas from '../../hooks/useAdminGetAllPersonas';
import useAdminCreatePersona from '../../hooks/useAdminCreatePersona';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import useAdminUpdatePersona from '../../hooks/useAdminUpdatePersona';

const editPersonaScheme = z.object({
  id: z.number(),
  persona: z.string().nonempty({ message: 'Persona is required' }),
});

const addPersonaScheme = z.object({
  persona: z.string().nonempty({ message: 'Persona is required' }),
});

const Personas = (): JSX.Element => {
  const navigate = useNavigate();
  const { data: personas, isLoading: isPersonasLoading } = useAdminGetAllPersonas();
  const { mutate: createPersona, isLoading: isCreatePersonaLoading } = useAdminCreatePersona();
  const { mutate: editPersona, isLoading: isEditPersonaLoading } = useAdminUpdatePersona();

  const editPersonaForm = useForm({
    initialValues: {
      id: 0,
      persona: '',
    },
    validate: zodResolver(editPersonaScheme),
  });

  const addPersonaForm = useForm({
    initialValues: {
      persona: '',
    },
    validate: zodResolver(addPersonaScheme),
  });

  const handleEditPersona = (values: { id: number; persona: string }): void => {
    editPersona(values);
  };

  const handleAddPersona = (values: { persona: string }): void => {
    createPersona(values);
  };

  return (
    <Container>
      <Grid justify="center">
        <Grid.Col span={12} md={10} lg={8} mt={'lg'}>
          {isPersonasLoading && 'Loading...'}
          {!isPersonasLoading && personas && (
            <>
              <Text td="underline" className="cursor-pointer" onClick={() => navigate(-1)}>
                Back to Dashboard
              </Text>

              <form onSubmit={editPersonaForm.onSubmit(handleEditPersona)}>
                <Title mt={'lg'}>Personas</Title>
                <Select
                  label="Choose a persona"
                  placeholder="Pick one"
                  data={
                    personas.map((persona) => ({
                      value: persona.persona,
                      label: persona.persona,
                    })) || []
                  }
                  value={editPersonaForm.values.persona}
                  onChange={(value) => {
                    editPersonaForm.setFieldValue('persona', value as string);
                    editPersonaForm.setFieldValue(
                      'id',
                      personas.find((persona) => persona.persona === value)?.id || 0,
                    );
                  }}
                />
                <Textarea
                  mt={'md'}
                  placeholder="Persona"
                  label="Persona"
                  minRows={5}
                  {...editPersonaForm.getInputProps('persona')}
                />
                <Button
                  mt={'lg'}
                  w={'100%'}
                  type="submit"
                  loading={isEditPersonaLoading}
                  loaderPosition="right"
                  loaderProps={{
                    ml: 'sm',
                  }}
                >
                  Save
                </Button>
              </form>
              <form onSubmit={addPersonaForm.onSubmit(handleAddPersona)}>
                <Title mt={'xl'}>Add Persona</Title>
                <Textarea
                  mt={'md'}
                  placeholder="Persona"
                  label="Persona"
                  minRows={5}
                  {...addPersonaForm.getInputProps('persona')}
                />
                <Button
                  mt={'lg'}
                  w={'100%'}
                  type="submit"
                  loading={isCreatePersonaLoading}
                  loaderPosition="right"
                  loaderProps={{
                    ml: 'sm',
                  }}
                >
                  Save
                </Button>
              </form>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Personas;
