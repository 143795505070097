import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useAdminStore } from 'store/adminStore/useAdminStore';
import { notifications } from '@mantine/notifications';
import router from 'router';

const showErrorNotification = (message: string): void => {
  notifications.show({
    message: `${message}`,
    autoClose: 5000,
    sx: (theme) => ({
      padding: theme.spacing.md,
      backgroundColor: theme.black,
    }),
    styles: (theme) => ({
      description: {
        color: theme.colors.red[4],
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,
      },
    }),
  });
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      onError: (e) => {
        const error = e as AxiosError;

        if (error && error.response) {
          if (error.response.status === 401) {
            router.navigate('/admin/login');
            useAdminStore.getState().logout();
          } else if (error.response.status !== 404) {
            showErrorNotification(error.response?.data as string);
          }
        }
      },
    },
    mutations: {
      onError: (e) => {
        const error = e as AxiosError;

        if (error && error.response) {
          if (error.response.status === 401) {
            useAdminStore.getState().logout();
          } else if (error.response.status !== 404) {
            showErrorNotification(error.response?.data as string);
          }
        }
      },
    },
  },
});
