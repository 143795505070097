import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import { ApiAdminUpdateFakeUserPhotosKey } from '../api/apiReferencesContants';
import { ApiAdminUpdateFakeUserPhotos } from '../api/apiReferences';

export const getAdminUpdateFakeUserPhotosKey = (): QueryKey => [ApiAdminUpdateFakeUserPhotosKey];

const useAdminUpdateFakeUserPhotos = (): {
  mutate: UseMutateFunction<void, unknown, FormData, unknown>;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getAdminUpdateFakeUserPhotosKey(),
    async (data: FormData) => {
      const res = await ApiAdminUpdateFakeUserPhotos(data);
      return res;
    },
    {
      onSuccess: async () => {
        notifications.show({
          message: `Photos updated successfully!`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.green[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useAdminUpdateFakeUserPhotos;
