import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiAdminCheckSessionKey } from '../api/apiReferencesContants';
import { Admin } from 'services/admin/types';
import { useAdminStore } from 'store/adminStore/useAdminStore';
import { ApiAdminCheckSession } from '../api/apiReferences';

export const getAdminAuthKey = (): QueryKey => [ApiAdminCheckSessionKey];

const useAdminAuth = (): {
  isLoading: boolean;
  data?: Admin;
} => {
  // Admin store
  const setAdmin = useAdminStore((state) => state.setAdmin);

  const { data, isLoading } = useQuery(
    getAdminAuthKey(),
    async () => {
      const res = await ApiAdminCheckSession();
      return res;
    },
    {
      onSuccess: (data) => {
        setAdmin(data);
      },
    },
  );

  return {
    isLoading,
    data,
  };
};

export default useAdminAuth;
