import { QueryKey, useQuery } from '@tanstack/react-query';
import { ApiAdminGetAllPersonas } from '../api/apiReferences';
import { Persona } from '../types';
import { ApiAdminGetAllPersonasKey } from '../api/apiReferencesContants';

export const getAdminGetAllPersonasKey = (): QueryKey => [ApiAdminGetAllPersonasKey];

const useAdminGetAllPersonas = (): {
  isLoading: boolean;
  data?: Persona[];
} => {
  const { data, isLoading } = useQuery(getAdminGetAllPersonasKey(), async () => {
    const res = await ApiAdminGetAllPersonas();
    return res;
  });

  return {
    isLoading,
    data,
  };
};

export default useAdminGetAllPersonas;
