import { MantineThemeOverride } from '@mantine/core';
import { rem } from '@mantine/core';

export const theme: MantineThemeOverride = {
  colorScheme: 'light',
  focusRing: 'never',
  // Font
  fontFamily: 'Helvetica Neue, sans-serif',
  headings: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 400,
  },
  // Colors
  colors: {
    beige: [
      '#F9F7F3',
      '#F8F5EF',
      '#F7F2E9',
      '#F6F0E4',
      '#F5EDE0',
      '#F4EADC',
      '#F3E7D7',
      '#F2E4D3',
      '#F1E1CE',
      '#F0DEC9',
    ],
    red: [
      '#FBE9E9',
      '#F4C2C2',
      '#ED9C9C',
      '#E67575',
      '#DF4E4E',
      '#D82727',
      '#AD1F1F',
      '#821717',
      '#561010',
      '#2B0808',
    ],
    green: [
      '#EEF7EE',
      '#D1E8CF',
      '#B3D9B0',
      '#95CA91',
      '#78BB72',
      '#5AAC53',
      '#488A42',
      '#366732',
      '#244521',
      '#122211',
    ],
    blue: [
      '#EEF0F7',
      '#CFD4E8',
      '#B0B9D9',
      '#919DCA',
      '#7282BB',
      '#5367AC',
      '#42528A',
      '#323E67',
      '#212945',
      '#111522',
    ],
  },
  primaryColor: 'dark',
  // Radius
  defaultRadius: 0,
  // Spacing
  spacing: {
    xs: rem(4),
    sm: rem(8),
    md: rem(16),
    lg: rem(32),
    xl: rem(64),
  },
};
