import { Button, Container, FileInput, Grid, MultiSelect, Text, Title } from '@mantine/core';
import './FakeUserPictures.scss';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { useState } from 'react';
import useAdminGetAllFakeUsers from '../../hooks/useAdminGetAllFakeUsers';
import useAdminUpdateFakeUserPhotos from '../../hooks/useAdminUpdateFakeUserPhotos';

const editPhotosScheme = z.object({
  usernames: z.array(z.string()).max(10),
});

const Timings = (): JSX.Element => {
  const navigate = useNavigate();
  const { data: users, isLoading: isUsersLoading } = useAdminGetAllFakeUsers();
  const { mutate: editPhotos, isLoading: isUpdatingPhotos } = useAdminUpdateFakeUserPhotos();
  const [files, setFiles] = useState<File[]>([]);

  const editPhotosForm = useForm({
    initialValues: {
      usernames: [],
    },
    validate: zodResolver(editPhotosScheme),
  });

  const handleEditPhotos = (values: z.infer<typeof editPhotosScheme>): void => {
    const usernames = values.usernames.toString();
    const formData = new FormData();
    formData.append('usernames', usernames);
    files.forEach((file) => {
      formData.append('photos', file);
    });

    editPhotos(formData);
  };

  return (
    <Container>
      <Grid justify="center">
        <Grid.Col span={12} md={10} lg={8} mt={'lg'}>
          {isUsersLoading && 'Loading...'}
          {!isUsersLoading && users && (
            <>
              <Text td="underline" className="cursor-pointer" onClick={() => navigate(-1)}>
                Back to Dashboard
              </Text>

              <form onSubmit={editPhotosForm.onSubmit(handleEditPhotos)}>
                <Title mt={'lg'}>Update photos</Title>
                <MultiSelect
                  data={users.map((user) => ({
                    value: user.username,
                    label: user.username,
                  }))}
                  label="Choose usernames"
                  placeholder="Choose upto 10 usernames"
                  maxSelectedValues={10}
                  {...editPhotosForm.getInputProps('usernames')}
                />
                <FileInput
                  multiple
                  value={files}
                  onChange={setFiles}
                  mt={'lg'}
                  label="Select photos"
                  placeholder="Choose upto 10 photos"
                />
                <Button
                  mt={'lg'}
                  w={'100%'}
                  type="submit"
                  loading={isUpdatingPhotos}
                  loaderPosition="right"
                  loaderProps={{
                    ml: 'sm',
                  }}
                >
                  Save
                </Button>
              </form>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Timings;
