import Api from 'common/utils/axios/http';
import { Persona, PinComment, PromptDetails, Timing, User } from '../types';

export const ApiAdminCreatePersona = (data: { persona: string }): Promise<void> => {
  return Api.post('admin/persona', data).then((res) => res.data);
};

export const ApiAdminGetAllPersonas = (): Promise<Persona[]> => {
  return Api.get('admin/persona').then((res) => res.data);
};

export const ApiAdminGetPersona = (id: number): Promise<Persona> => {
  return Api.get(`admin/persona/${id}`).then((res) => res.data);
};

export const ApiAdminUpdatePersona = (id: number, persona: string): Promise<Persona> => {
  return Api.put(`admin/persona/${id}`, {
    persona,
  }).then((res) => res.data);
};

export const ApiAdminDeletePersona = (id: number): Promise<void> => {
  return Api.delete(`admin/persona/${id}`).then((res) => res.data);
};

export const ApiAdminGeneratePin = (data: {
  persona: string;
  appContext: string;
  count: number;
  task: string;
  temp: number;
  tokenLimit: number;
  rules: string;
}): Promise<string> => {
  return Api.put('admin/pin/generate', data).then((res) => res.data);
};

export const ApiAdminCreatePin = (data: {
  message: string;
  lat: number;
  lng: number;
}): Promise<string> => {
  return Api.post('admin/pin', data).then((res) => res.data);
};

export const ApiAdminGetAllPinComments = (limit: number, offset: number): Promise<PinComment[]> => {
  return Api.get(`admin/pin/comments?limit=${limit}&offset=${offset}`).then((res) => res.data);
};

export const ApiAdminDeletePinComment = (id: number): Promise<void> => {
  return Api.delete(`admin/pin/comments/${id}`).then((res) => res.data);
};

export const ApiAdminGetPromptDetails = (type: string): Promise<PromptDetails> => {
  return Api.get(`admin/pin/prompt-details/${type}`).then((res) => res.data);
};

export const ApiAdminUpdatePromptDetails = (data: {
  id: number;
  context: string;
  task: string;
  rules: string;
}): Promise<void> => {
  return Api.put('admin/pin/prompt-details', data).then((res) => res.data);
};

export const ApiAdminGetTimings = (): Promise<Timing> => {
  return Api.get(`admin/timings`).then((res) => res.data);
};

export const ApiAdminUpdateTimings = (data: {
  id: number;
  maxMin: number;
  minMin: number;
  minComments: number;
  maxComments: number;
  minLikes: number;
  maxLikes: number;
  minShares: number;
  maxShares: number;
}): Promise<void> => {
  return Api.put('admin/timings', data).then((res) => res.data);
};

export const ApiAdminUpdateFakeUserPhotos = (data: FormData): Promise<void> => {
  return Api.put(`admin/account/photos`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((res) => res.data);
};

export const ApiAdminGetAllFakeUsers = (): Promise<User[]> => {
  return Api.get(`admin/account/fake-users`).then((res) => res.data);
};
