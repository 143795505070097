import { useLocation, Routes as DomRoutes, Route, Navigate } from 'react-router-dom';
import Login from 'services/admin/auth/pages/Login';
import Comments from 'services/admin/dashboard/pages/Comments';
import Dashboard from 'services/admin/dashboard/pages/Dashboard';
import FakeUserPictures from 'services/admin/dashboard/pages/FakeUserPictures';
import GeneratePin from 'services/admin/dashboard/pages/GeneratePin';
import Personas from 'services/admin/dashboard/pages/Personas';
import Timings from 'services/admin/dashboard/pages/Timings';
import Home from 'services/home/pages/Home';

const Routes = (): JSX.Element => {
  const location = useLocation();

  return (
    <DomRoutes key={location.key} location={location}>
      {/* Admin */}
      <Route path="/admin/">
        <Route path="" element={<Dashboard />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="generate-pin" element={<GeneratePin />}></Route>
        <Route path="personas" element={<Personas />}></Route>
        <Route path="comments" element={<Comments />}></Route>
        <Route path="timings" element={<Timings />}></Route>
        <Route path="fake-users" element={<FakeUserPictures />}></Route>
      </Route>

      {/* Home */}
      <Route path="/" element={<Home />}></Route>

      <Route path="*" element={<Navigate to="/" />}></Route>
    </DomRoutes>
  );
};

export default Routes;
