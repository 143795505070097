import { queryClient } from 'common/utils/queryClient';
import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import { ApiAdminUpdateTimingsKey } from '../api/apiReferencesContants';
import { ApiAdminUpdateTimings } from '../api/apiReferences';
import { getAdminGetTimingsKey } from './useAdminGetTimings';

export const getAdminUpdateTimingsKey = (): QueryKey => [ApiAdminUpdateTimingsKey];

const useAdminUpdateTimings = (): {
  mutate: UseMutateFunction<
    void,
    unknown,
    {
      id: number;
      maxMin: number;
      minMin: number;
      minComments: number;
      maxComments: number;
      minLikes: number;
      maxLikes: number;
      minShares: number;
      maxShares: number;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate, isLoading } = useMutation(
    getAdminUpdateTimingsKey(),
    async (data: {
      id: number;
      maxMin: number;
      minMin: number;
      minComments: number;
      maxComments: number;
      minLikes: number;
      maxLikes: number;
      minShares: number;
      maxShares: number;
    }) => {
      const res = await ApiAdminUpdateTimings(data);
      return res;
    },
    {
      onSuccess: async () => {
        notifications.show({
          message: `Timings updated successfully!`,
          autoClose: 5000,
          sx: (theme) => ({
            padding: theme.spacing.md,
            backgroundColor: theme.black,
          }),
          styles: (theme) => ({
            description: {
              color: theme.colors.green[4],
              fontWeight: 500,
              fontSize: theme.fontSizes.sm,
            },
          }),
        });

        await queryClient.invalidateQueries(getAdminGetTimingsKey());
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useAdminUpdateTimings;
