import { QueryKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiAdminLoginKey } from '../api/apiReferencesContants';
import { useAdminStore } from 'store/adminStore/useAdminStore';
import { ApiAdminLogin } from '../api/apiReferences';
import { Admin } from 'services/admin/types';
import { useNavigate } from 'react-router-dom';

export const getAdminLoginKey = (): QueryKey => [ApiAdminLoginKey];

const useAdminLogin = (): {
  mutate: UseMutateFunction<
    Admin,
    unknown,
    {
      email: string;
      password: string;
    },
    unknown
  >;
  isLoading: boolean;
} => {
  const setAdmin = useAdminStore((state) => state.setAdmin);
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    getAdminLoginKey(),
    async (data: { email: string; password: string }) => {
      const res = await ApiAdminLogin(data);
      return res;
    },
    {
      onSuccess: (data) => {
        setAdmin(data);
        navigate('/admin');
      },
    },
  );

  return {
    mutate,
    isLoading,
  };
};

export default useAdminLogin;
